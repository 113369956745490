import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import { RichText } from 'prismic-reactjs'
import Layout from '../components/layouts'

const Page = ({data}) => {
  if (!data) return null
  const document = data.allPrismicPhotogroup.edges[0].node

    return (
      <Layout>
        <SEO title={RichText.asText(document.data.title)} />
        <div className="mt-5">
          <div className="page__content">
            <div className="media__body__center mb-5">
              <div className="eyebrow">
                Photography
              </div>
              <h1 className="display__title">
                {RichText.asText(document.data.title)}
              </h1>
              <div className="description">
                {RichText.asText(document.data.description)}
              </div>
            </div>
          </div>

            <div className="page__content">
              <div className="media__body__center">
                {document.data.photos.map((photo) => (
                  <div className="mb-3 text-center">
                    <img
                      src={photo.photo.url}
                      className="img-fluid"
                      alt={photo.alt}
                    />
                  </div>
                ))}
              </div>
            </div>
        </div>
      </Layout>
    )
  }

// Query for the Blog Post content in Prismic
export const query = graphql`
query PhotogroupQuery($uid: String) {
  allPrismicPhotogroup(filter: {uid: {eq: $uid}}) {
    edges {
      node {
        data {
          description {
            type
            text
          }
          title {
            type
            text
          }
          featured_image {
            url
            alt
          }
          photos {
            caption {
              type
              text
            }
            photo {
              url
              alt
            }
          }
        }
      }
    }
  }
}
`

export default Page
